import React from "react";
import axios from "axios";

// reactstrap components
import {
  Radio,
  RadioButtons,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";


// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";

function GuestsPage(event) {
  axios.defaults.baseURL = 'https://diibox.de/api/';

  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const [formState, setFormState] = React.useState({ proof: {} });
  const [errorTexts, setErrorTexts] = React.useState([]);
  const [successText, setSuccessText] = React.useState(undefined);

  const handleChange = (e, name) => {
    if (name === "proof") {
      console.log("change: ", e.target.checked, name, e.target.name);
      const newState = { ...formState };
      newState.proof = { ...formState.proof };
      newState.proof[e.target.name] = e.target.checked;
      setFormState(newState);
    } else {
      console.log("change: ", e.target.value, name);
      const newFormState = { ...formState };
      newFormState[name] = e.target.value;
      setFormState(newFormState);
      console.log("new form state: ", formState);
    }
  }

  const formSubmit = () => {
    console.log("submitting: ", formState);

    let errors = [];
    if (!formState.name) {
      errors.push("Ein Name ist erforderlich");
    }
    if (!formState.email) {
      errors.push("Eine eMail ist erforderlich");
    } else {
      let lastAtPos = formState.email.lastIndexOf('@');
      let lastDotPos = formState.email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && formState.email.indexOf('@@') == -1 && lastDotPos > 2 && (formState.email.length - lastDotPos) > 2)) {
        errors.push("Email is not valid");
      }
    }
    if (!formState.telephone || formState.telephone.length < 5) {
      errors.push("Eine gültige Telefonnummer ist erforderlich");
    }
    if (!formState.street || !formState.zip || !formState.city) {
      errors.push("Eine vollständige Addresse ist erforderlich");
    }
    if (!formState.proof.test && !formState.proof.impfung && !formState.proof.genesen) {
      errors.push("Entweder ein Test, ein Gesungs- oder ein Impfnachweis sind erforderlich");
    } 

    setErrorTexts(errors);
    if (errors.length === 0) {
      console.log("valid submit");
      setSuccessText("Done! Bitte überprüfe Dein E-Mail Postfach. Falls Du noch eine weitere Person hinzufügen möchtest, klicke einfach auf den Button");


      const body = { formState, eventId: event.id };

      axios
        .post('guests/submit', body)
        .then((res) => {
          console.log("RES: ", res);
          if (res.data.success) {
            console.log("SUCCESS!");
          } else {
            alert("error: " + res.data.error);
          }
        }, (err) => {
          alert("error occured");
        });

    } else {
      setSuccessText(undefined);
    }
  };

  const navbarStyle = {
    backgroundcolor: event.navbarcolor,
  };

  return (
    <>

      <ExamplesNavbar {...navbarStyle} />
      {/* <div className="page-header clear-filter" filter-color="blue" */}
	<div className="page-header clear-filter" filter-color="gray"
        style={{
          "max-height": "2200px"
        }}
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/"+event.bgimg) + ")",
          }}
        ></div>

{/*
<div className="content" style={{"margin-top": "80px"}}>
<div
                        className="big-logo-container"
                        style={{
                          "margin-top": "25px", 
                        }}
                      >
                        <img
                          alt="..."
                          src={require("assets/img/" + event.logo)}
                        ></img>
                      </div>
  </div>
                      */}

        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form className="form" onSubmit={formSubmit} id="guestform">
                  <CardHeader className="text-center">
                    {event.logo2 ?
                      <div style={{ height: "100%", width: "100%" }}>
                        <div
                          className="big-logo-container"
                          style={{
                            "margin-top": "25px",
                            "margin-right": "5px",
                            "display": "inline-block",
                            "*display": "inline",
                            zoom: 1,
                            "vertical-align": "top",
                            width: "45%"
                          }}
                        >
                          <img
                            alt="..."
                            src={require("assets/img/" + event.logo)}
                          ></img>
                        </div>

                      </div>
                      : 
                      <div
                        className="big-logo-container"
                        style={{
                          "margin-top": "25px", 
                        }}
                      >
                        <img
                          alt="..."
                          src={require("assets/img/" + event.logo)}
                        ></img>
                      </div>}

                    <Container>
                      <div>
                        <h4 className="title" style={{ "fontFamily": event.font }}>{event.name}</h4>
                        <h5 className="description" style={{ color: "#eeeeee", "fontStyle": "normal", "fontFamily": event.font }}>
                          <b>Wann?</b> {event.time}<br />
                          <b>Wo?</b> {event.place}
                        </h5>
                        <h5 className="description" style={{ color: "#eeeeee", "fontStyle": "italic", "fontFamily": event.font }}>
                          {event.description.split("\n").map(line => {
                            return (<>{line}<br /></>);
                          })}
                        </h5>
                        {(event.instagram || event.facebook) ?
                          <h5>
                            {event.instagram ?
                              <a href={"https://www.instagram.com/" + event.instagram + "/"} title={event.instagram} target="_blank" style={{ margin: "10px", color: "white" }}>
                                <i className="fab fa-instagram"></i>
                              </a> : <></>}
                            {event.instagram2 ?
                              <a href={"https://www.instagram.com/" + event.instagram2 + "/"} title={event.instagram2} target="_blank" style={{ margin: "10px", color: "white" }}>
                                <i className="fab fa-instagram"></i>
                              </a> : <></>}
                            {event.facebook ?
                              <a href={"https://www.facebook.com/" + event.facebook + "/"} target="_blank" style={{ margin: "10px", color: "white" }}>
                                <i className="fab fa-facebook"></i>
                              </a> : <></>}
                          </h5>
                          : <></>
                        }
                        {event.youtube ?
                          <>
                            <iframe
                              width="100%"
                              // height="315" 
                              src={"https://www.youtube.com/embed/" + event.youtube}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen>

                            </iframe>
                          </>
                          : <></>}
                        {event.youtube2 ?
                          <>
                            <iframe
                              width="100%"
                              // height="315" 
                              src={"https://www.youtube.com/embed/" + event.youtube2}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen>

                            </iframe>
                          </>
                          : <></>}
                      </div>
                    </Container>

                  </CardHeader>

                  {event.capacityReached ? <>! Bereits ausgebucht !</> : <div>

                    <CardBody>

                      {event.capacityReached ?
                        <div>! Bereits ausgebucht !</div>
                        : <></>}


<InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >



                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <select
                          disabled={event.capacityReached}
                          placeholder="Geschlecht"
                          type="text"
                          name="name"
                          style={{ "fontFamily": event.font, "fontSize": "0.95em", backgroundColor: "rgba(255, 255, 255, 0.1)",
                        color: "white", "padding-left": "15px" }}

                          onChange={(e) => handleChange(e, "name")}
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                        >
                          <option disabled selected>Geschlecht</option>
                          <option>Keine Angabe</option>
                          <option>Frau</option>
                          <option>Mann</option>
                          <option>Non-Binär</option>

                        </select>
                      </InputGroup>


                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >



                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          disabled={event.capacityReached}
                          placeholder="Name *"
                          type="text"
                          name="name"
                          style={{ "fontFamily": event.font, "fontSize": "0.95em", backgroundColor: "rgba(255, 255, 255, 0.1)" }}

                          onChange={(e) => handleChange(e, "name")}
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                        ></Input>
                      </InputGroup>

                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >

                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          disabled={event.capacityReached}
                          placeholder="E-Mail *"
                          type="email"
                          name="email"
                          style={{ "fontFamily": event.font, "fontSize": "0.95em" }}

                          onChange={(e) => handleChange(e, "email")}
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                          required />
                      </InputGroup>


                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >

                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          disabled={event.capacityReached}
                          placeholder="Telefon *"
                          type="telephone"
                          name="telephone"
                          style={{ "fontFamily": event.font, "fontSize": "0.95em" }}

                          onChange={(e) => handleChange(e, "telephone")}
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                          required></Input>
                      </InputGroup>
                      <br />

                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >

                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          disabled={event.capacityReached}
                          placeholder="Straße &amp; Hausnr. *"
                          type="text"
                          name="street"
                          style={{ "fontFamily": event.font, "fontSize": "0.95em" }}

                          onChange={(e) => handleChange(e, "street")}
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                        ></Input>
                      </InputGroup>

                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >

                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          disabled={event.capacityReached}
                          placeholder="PLZ *"
                          type="text"
                          name="zip"
                          style={{ "fontFamily": event.font, "fontSize": "0.95em" }}

                          onChange={(e) => handleChange(e, "zip")}
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                        ></Input>
                      </InputGroup>

                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >

                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          disabled={event.capacityReached}
                          placeholder="Ort *"
                          type="text"
                          name="city"
                          style={{ "fontFamily": event.font, "fontSize": "0.95em" }}

                          onChange={(e) => handleChange(e, "city")}
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                        ></Input>
                      </InputGroup>

                      <InputGroup>
                        <Label
                          style={{ "fontFamily": event.font, "fontSize": "0.95em" }}
                        >
                          <div style={{ "margin-left": "30px" }}>
                            <Input type="checkbox" name="test"
                              disabled={event.capacityReached}
                              onChange={(e) => handleChange(e, "proof")}
                            />
                            Getestet
                          </div>
                        </Label>
                        <Label
                          style={{ "fontFamily": event.font, "fontSize": "0.95em" }}
                        >
                          <div style={{ "margin-left": "30px" }}>
                            <Input type="checkbox" name="impfung"
                              disabled={event.capacityReached}
                              onChange={(e) => handleChange(e, "proof")}
                            />
                            Geimpft
                          </div>
                        </Label>
                        <Label
                          style={{ "fontFamily": event.font, "fontSize": "0.95em" }}
                        >
                          <div style={{ "margin-left": "30px" }}>
                            <Input type="checkbox" name="genesen"
                              disabled={event.capacityReached}
                              onChange={(e) => handleChange(e, "proof")}
                            />
                            Genesen
                          </div>
                        </Label>
                      </InputGroup>
                      <div style={{ "margin-top": "-20px", color: "#bbbbbb" }}><i>Bitte Nachweis am Eingang bereithalten</i></div>


                    </CardBody>
                    <CardFooter className="text-center">



                      <div style={{ "margin-top": "-20px", color: "#bbbbbb" }}><i>* = erforderlich</i></div>

                      <Button
                        disabled={event.capacityReached}
                        block
                        type="submit"
                        style={{ "fontFamily": event.font, "fontSize": "1.1em", "background-color": event.navbarcolor }}
                        className="btn-round"
                        color="info"
                        onClick={(e) => {
                          e.preventDefault();
                          formSubmit();
                        }}
                        size="lg"
                      >
                        Jetzt anmelden!
                      </Button>

                      {errorTexts.length == 0
                        ? <></>
                        : <ul
                          style={{ color: "#ff4444" }}
                        >
                          {errorTexts.map(err => {
                            return (<li>{err}</li>);
                          })}

                        </ul>}
                      {successText !== undefined
                        ? <>
				<div style={{ color: "#33ff33" }}>{successText}</div>
                          <Button
                        block
                        style={{ "fontFamily": event.font, "fontSize": "1.1em", "background-color": "black" }}
                        className="btn-round"
                        color="info"
                        onClick={(e) => {
                          e.preventDefault();
			  document.getElementById("guestform").reset();
                          setFormState({proof: {}});
                        }}
                        size="lg"
                      >
                        Formular leeren
                      </Button>
			  </>
			: <></>}
                    </CardFooter>

                  </div>}

                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default GuestsPage;
